import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Layout";
import Link from "../components/Link";

const NotFoundPage = ({ data, location }) => {
  return (
    <Layout location={location} seoTitle="404" hasMenuIcon>
      <div className="pt-hh px-ogs pb-ogs">
        <h1 className="text-xl lg:text-2xl xl:text-3xl leading-relaxed">404</h1>
        <p className="text-base md:text-lg lg:text-xl leading-relaxed mb-2">
          Sorry, but the page you were looking for could not be found.
        </p>
        <Link className="link" to="/work/">
          Our Work
        </Link>
      </div>
    </Layout>
  );
};

NotFoundPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default NotFoundPage;
